<template>
	<div class="page-container">
		<nav-bar title="继续教育在线报名" :fixed="true" id="navbar"></nav-bar>
		<!-- 报名表单 -->
		<div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
			<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
				辽宁继续教育学员注册入口!
			</van-notice-bar>
			<van-form @submit="onConfirm" ref="formData" action="">
				<van-field
					v-model="form.sfzjh"
					name="sfzjh"
					label="身份证号"
					placeholder="身份证号码"
					maxlength="18"
				/>
				<van-field
					v-model="form.xm"
					name="xm"
					label="真实姓名"
					placeholder="真实姓名"
				/>
<!--				<van-field name="xb" label="性别">-->
<!--					<template #input>-->
<!--						<van-radio-group v-model="form.xb" direction="horizontal">-->
<!--							<van-radio name="男">男</van-radio>-->
<!--							<van-radio name="女">女</van-radio>-->
<!--						</van-radio-group>-->
<!--					</template>-->
<!--				</van-field>-->
<!--				<van-field-->
<!--					v-model="form.sjhm"-->
<!--					name="sjhm"-->
<!--					type="tel"-->
<!--					label="手机号码"-->
<!--					placeholder="手机号码"-->
<!--					maxlength="11"-->
<!--				/>-->
<!--				<van-field-->
<!--					v-model="form.xsczsj"-->
<!--					name="xsczsj"-->
<!--					label="上次学时"-->
<!--					placeholder="上次学时截止时间(如:202206)"-->
<!--				/>-->
<!--				<van-field-->
<!--					v-model="form.ccfzsj"-->
<!--					name="ccfzsj"-->
<!--					label="初次发证"-->
<!--					placeholder="初次发证时间(如:20110621)"-->
<!--				/>-->
				<div class="title">
					<span style="color: #409EFF;">请如实填写以下报名信息</span>
				</div>
				<!-- 可选课程 -->
				<van-field
					readonly
					clickable
					name="pxcx"
					v-model="form.pxcx"
					label="可选课程"
					placeholder="点击选择可选课程"
					@click="showPicker = true"
					:rules="[{ required: true, message: '请选择学习课程' }]"
				/>
				<van-popup v-model="showPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="columns"
						@confirm="onConfirmPicker"
						@cancel="showPicker = false"
						value-key="text"
					/>
				</van-popup>
				<!-- 所在地区 -->
				<van-field
					readonly
					clickable
					name="szdq"
					v-model="form.szdq"
					label="所在地区"
					placeholder="点击选择所在地区"
					@click="showPickerDq = true"
				/>
				<van-popup v-model="showPickerDq" position="bottom">
					<van-picker
						show-toolbar
						:columns="columnsDq"
						@confirm="onConfirmPickerDq"
						@cancel="showPickerDq = false"
						value-key="text"
						:default-index="0"
					/>
				</van-popup>
				<!-- 培训年度 -->
				<van-field
					readonly
					clickable
					name="pxnd"
					v-model="form.pxnd"
					label="培训年度"
					placeholder="点击选择培训年度"
					@click="showPickerNd = true"
					:rules="[{ required: true, message: '请选择培训年度' }]"
				/>
				<van-popup v-model="showPickerNd" position="bottom">
					<van-picker
						show-toolbar
						:columns="columnsNd"
						@confirm="onConfirmPickerNd"
						@cancel="showPickerNd = false"
						value-key="text"
						:default-index="0"
					/>
				</van-popup>
				<div style="margin: 16px">
					<van-button round block type="info" native-type="submit">下一步</van-button>
				</div>
			</van-form>
		</div>

		<!-- 报名信息确认 -->
		<div class="confirm-area" v-if="isSignupConfirm">
			<div class="checked-plan">
				<van-cell-group title="请确认您的报名信息" inset>
					<van-cell title="真实姓名" :value="form.xm"/>
					<van-cell title="身份证号" :value="form.sfzjh"/>
<!--					<van-cell title="性别" :value="form.xb"/>-->
<!--					<van-cell title="手机号码" :value="form.sjhm"/>-->
<!--					<van-cell title="上次学时" :value="form.xsczsj"/>-->
<!--					<van-cell title="初次发证" :value="form.ccfzsj"/>-->
					<van-cell title="报名课程" :value="form.pxcx"/>
					<van-cell title="培训年度" :value="form.pxnd"/>
					<van-cell title="所在地区" :value="form.szdq"/>
					<div style="margin: 16px; text-align: center">
						<van-button round plain type="info" native-type="button" @click="onModify"> 修改</van-button>&nbsp;
						<van-button round type="info" native-type="button" @click="onSubmit"> 确认提交</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- 报名成功 -->
		<div class="checked-area" v-if="isSignupSuccess">
			<van-icon name="checked" color="#67C23A" size="80"/>
			<div><span class="checked-msg">恭喜您，报名成功！</span></div>
			<div class="checked-plan">
				<van-cell-group title="报名信息" inset>
					<van-cell title="真实姓名" :value="form.xm"/>
					<van-cell title="身份证号" :value="form.sfzjh"/>
<!--					<van-cell title="性别" :value="form.xb"/>-->
<!--					<van-cell title="手机号码" :value="form.sjhm"/>-->
<!--					<van-cell title="上次学时" :value="form.xsczsj"/>-->
<!--					<van-cell title="初次发证" :value="form.ccfzsj"/>-->
					<van-cell title="报名课程" :value="form.pxcx"/>
					<van-cell title="培训年度" :value="form.pxnd"/>
					<van-cell title="所在地区" :value="form.szdq"/>
					<div style="margin: 16px">
						<van-button
							round
							block
							type="info"
							native-type="button"
							@click="goOnSignup"
						>继续报名
						</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '提交中···' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar} from "vant";
	import {pushSignupInfoLN} from "../../api/user";

	export default {
		name: "signup-liaoning-push",
		components: {NavBar},
		data() {
			return {
				title: '',
				showLoading: false,
				isSignupSuccess: false,
				isSignupConfirm: false,
				searchResult: false,
				showPicker: false,
				showPickerNd: false,
				showPickerDq: false,
				showPickerSy: false,
				form: {
					szdq: '辽阳市',
					szdq_value: '211000',
					pxnd: '2022年度',
					pxnd_value: '2022',
					xb: '未知',
					sjhm: '',
					xsczsj: '',
					ccfzsj: '',

				},
				columns: [
					{text: '道路旅客运输驾驶员', value: '001'},
					{text: '道路货物运输驾驶员', value: '002'},
					{text: '道路旅客运输驾驶员（出租车）', value: '004'},
					{text: '道路旅客运输驾驶员（巡游、网约出租车）', value: '005'},
					{text: '道路危险货物运输驾驶员', value: '101'}
				],
				columnsNd: [
					{text: '2022年度', value: '2022'}
				],
				columnsDq: [
					//{text: '沈阳市', value: '210100'},
					//{text: '大连市', value: '210200'},
					//{text: '鞍山市', value: '210300'},
					//{text: '抚顺市', value: '210400'},
					//{text: '本溪市', value: '210500'},
					//{text: '丹东市', value: '210600'},
					{text: '锦州市', value: '210700'},
					//{text: '营口市', value: '210800'},
					{text: '阜新市', value: '210900'},
					{text: '辽阳市', value: '211000'},
					//{text: '盘锦市', value: '211100'},
					{text: '铁岭市', value: '211200'},
					//{text: '朝阳市', value: '211300'},
					//{text: '葫芦岛市', value: '211400'}
				],
			}
		},
		methods: {
			onConfirmPicker(value) {
				this.form.pxcx = value.text;
				this.form.pxcx_value = value.value;
				this.showPicker = false;
			},
			onConfirmPickerDq(value) {
				this.form.szdq = value.text;
				this.form.szdq_value = value.value;
				this.showPickerDq = false;
			},
			onConfirmPickerNd(value) {
				this.form.pxnd = value.text;
				this.form.pxnd_value = value.value;
				this.showPickerNd = false;
			},
			onConfirm() {
				this.isSignupConfirm = true;
			},
			onModify() {
				this.isSignupConfirm = false;
			},
			onSubmit() {
				this.showLoading = true;
				pushSignupInfoLN({
					sfzjh: this.form.sfzjh,
					xm: this.form.xm,
					xb: this.form.xb,
					sjhm: this.form.sjhm,
					xsczsj: this.form.xsczsj,
					ccfzsj: this.form.ccfzsj,
					pxcx: this.form.pxcx_value,
					pxnd: this.form.pxnd_value,
					szdq: this.form.szdq_value,
					szdqmc: this.form.szdq
				})
				.then(() => {
					this.isSignupSuccess = true;
					this.isSignupConfirm = false;
					this.showLoading = false;
				})
				.catch(() => {
					this.showLoading = false;
				});
			},
			goOnSignup() {
				window.location.reload();
			},
			gotoStudy() {
				window.location.href = "/index.html#/?appNo=APP02";
			},
			onClickRight() {
				this.gotoStudy();
			},
		}
	}
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}

	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}

	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}

	.form-area {
		margin-top: 46px;
	}

	.title {
		margin: 0;
		padding: 16px 16px 16px;
		color: rgba(69, 90, 100, 0.6);
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}

	.checked-area {
		margin-top: 90px;
		text-align: center;
	}

	.confirm-area {
		text-align: center;
	}

	.checked-msg {
		font-size: 14px;
		color: #67c23a;
	}

	.checked-plan {
		text-align: left;
		margin-top: 40px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		border-radius: 15px;
		background-color: #fff;
		padding-top: 32px;
	}
</style>
